$(document).ready(function() {
	// Mobile Nav
	var mmenu = $('nav#mobnav').mmenu({onClick: {close: true}}, {pageSelector: ".wrapper"});
    var closeMenu = $("nav#mobnav").data( "mmenu" );
    $('.mm-close').on('click',function(){
        closeMenu.close();
    });

	$('#scroll-top').on('click', function(e) {
		$('html, body').animate({
			scrollTop: 0
		}, 1000);
		e.preventDefault();
	});

	// Custom slider navigation
    $(document).on('owl-ready', function(e, s) {
        s.on('initialized.owl.carousel', function(e) {
            if (e.item.count > 1) {
                s.parent().find('.owl-custom-nav').on('click', '.owl-prev', function() {
                    s.trigger('prev.owl.carousel');
                });
                s.parent().find('.owl-custom-nav').on('click', '.owl-next', function() {
                    s.trigger('next.owl.carousel');
                });
            } else {
                $('.owl-custom-nav').hide();
            }
        });
    });

	// Mehr News
	var showItem = 6;
    var newsItem = $('.newsblock');
    newsItem.slice(showItem,newsItem.length).hide();

    $('#allenews').on('click',function(){
        showItem +=6;
        newsItem.slice(showItem-6,showItem).each(function(i,obj){
            var self = this;
            setTimeout(function(){
                $(self).fadeIn(1000);
            },300*i);

        });

        if(newsItem.length < showItem){
            $(this).parent().addClass('out');
        }
    });

    var items = $('.timeline-list .timeline-list-item');

    function isElementInViewport(el) {
    	var rect = el.getBoundingClientRect();
    	return (
    		rect.top >= 0 &&
    		rect.left >= 0 &&
    		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    	);
    }

    function callbackFunc(m) {
    	for (var i = 0; i < items.length; i++) {
    		if (isElementInViewport(items[i])) {
    			items[i].classList.add("in-view");
    		}
    	}
    }

    $(window).load(function() {
        callbackFunc();
    });
    $(window).scroll(function() {
        callbackFunc();
    });
});
