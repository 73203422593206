//var user = pimcore.globalmanager.get("user");
// CKEDITOR.editorConfig = function( config )
// {
//     config.forcePasteAsPlainText = true;                                        // Eingefügter Text hat automatisch keine Formatierung
//     config.format_tags = 'p;h2;h3;h4;h5;h6;div';                                // zugelassene textformatierung
//     config.colorButton_enableMore = false;                                      // eigene Farben als Hexwert angeben
//     //if(!user.admin)
//     if(true)
//     {
//         config.toolbar = [
//                         ['Cut','Copy','Paste'],
//                         ['NumberedList','BulletedList'],
//                         ['Link','Unlink'],
//                         ['SpecialChar'],
//                         ['Undo','Redo','-','RemoveFormat'],
//                         ['ImageButton'],
//                         ['Format', 'Bold','Italic','Underline','Strike'],
//                         ['Maximize', 'ShowBlocks']
//         ];
//     }
// };
