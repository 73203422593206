(function($){
	$.fn.wvImmobilien = function(options){
		if(!options){var options = {};}
		return this.each(function() {
			options.obj = $(this);
			new wvImmobilien(options);
		});
	}
}(jQuery));

var wvImmobilien = function(options,fx){
	this.options = $.extend({
		id : "",
		obj : false,
		view : 'table',
        imageContent : '.wvMobilien-Image',
        sort : false
	}, options);

	// Options to Attributs
	for(var name in this.options){eval("this."+name+"=this.options."+name);}

    // Attribut
    if(this.view == 'table') {
		this.tab = this.obj.find("table");
	    this.defaultImage = this.tab.attr("data-image");
	    this.rows = this.tab.find("tbody tr");
	} else {
		this.tab = this.obj.find(".wvMobilien-buttons");
	    this.defaultImage = this.tab.first().attr("data-image");
	    this.rows = this.tab.find("button");
	}

	this.map = false;
	this.imageContent = $(this.imageContent);
	this.search = this.obj.find("input.search");

    this.init();
}

wvImmobilien.prototype = {
    init:function(){

        var self = this;

		this.search.keyup(function(){
            self.colTextFilter();
        });

        this.imageContent.not('.wvMobilien-Image').addClass("wvMobilien-Image");

        // Start Image
        if(this.defaultImage){
            this.setImage(this.defaultImage);
			//this.setMarker();
        }

        // Sort
        if(this.sort){
            this.tab.tablesorter();
        }

        // Hover
        this.rows.hover(function(){
            self.rows.removeClass("select");
            $(this).addClass("select");
            self.setImage($(this).attr("data-image"));
        });

		this.rows.on('click',function(){
			if ($(this).data('href')) {
				document.location.href = $(this).data('href');
			}
		});
	},

    setImage:function(img){
        var self = this;
        if(img){
            this.imageLoader(img,function(src){
                if(self.imageContent.children("img").length){
                    self.imageContent.children("img").attr('src',src);
                }else{
                    self.imageContent.append($('<img>').attr('src',src).attr('usemap','#map-'+self.id));
                }
            });
        }
    },

	// setMarker:function(){
	// 	var self = this;
	// 	this.map = $('<map>');
	// 	this.map.attr('name','map-'+self.id);
	// 	this.rows.each(function(key,obj) {
	// 		if ($(obj).data('marker')) {
	// 			var area = $('<area>').attr('shape','poly');
	// 			area.attr('href','#');
	// 			area.attr('coords',$(obj).data('marker'));
	// 			self.map.append(area);
	// 		}
	// 	});
	//
	// 	this.imageContent.append(this.map);
	// },

    imageLoader:function(img,fx,fxErr){
		if(img.complete||img.readyState===4){
			img.src+="?d="+new Date().getTime();
			$(img).load(function(response,status,xhr){if(fx){fx(img);}}).error(function(){if(fxErr){fxErr();}});
		}else{
			if(fx){fx(img);}
		}
	},

    colTextFilter:function(){
        var value = String(this.search.val()).toLowerCase();

        this.rows.each(function(key,row){
            if(value != ""){
                var status = false;
                $(row).find('td .data').each(function(key,obj){
                    var text = String($(obj).text()).toLowerCase();
                    if(text.search(value) != -1){
                        status = true;
                    }
                });
                if(status){
                    $(row).removeClass('filter-hide');
					$(row).addClass('filter-show');
                }else{
                    $(row).addClass('filter-hide');
					$(row).removeClass('filter-show');
                }
            }else{
                $(row).removeClass('filter-hide');
				$(row).addClass('filter-show');
            }
        });
    }

}
